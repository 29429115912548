import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axios from "axios";
import clone from "clone";
import styles from "./Calender.module.scss";

const Calender = (props) => {
  const [month, setMonth] = useState([]);
  const [firstOfMonth, setFirstOfMonth] = useState();
  const [lastOfMonth, setLastOfMonth] = useState();

  const setUpHebrewCalendar = async () => {
    if (!month?.length) return;
    let monthStart = dayjs(new Date(month[0][0].day));
    let monthEnd = dayjs(new Date(month[month.length - 1][6].day));
    let clonedMonth = clone(month);

    let re = await axios.get(
      `https://www.hebcal.com/converter?cfg=json&start=${monthStart.format(
        "YYYY-MM-DD"
      )}&end=${monthEnd.format("YYYY-MM-DD")}&g2h=1`
    );

    console.log(re.data);

    let updated = await clonedMonth.map((week) =>
      week.map((day) => ({
        ...day,
        hd: re.data.hdates[dayjs(new Date(day.day)).format("YYYY-MM-DD")],
      }))
    );
    setMonth(updated);
  };

  const setUpMonth = async (date = new Date()) => {
    setFirstOfMonth(dayjs(date).startOf("day").startOf("month"));
    setLastOfMonth(dayjs(date).endOf("month").startOf("day"));

    let monthStart = dayjs(date)
      .startOf("day")
      .startOf("month")
      .startOf("week");

    let monthEnd = dayjs(date).endOf("month").endOf("week").startOf("day");
    // console.log(monthEnd.diff(monthStart, "week"));
    const weeksInMonth = monthEnd.diff(monthStart, "week");
    // console.log(weeksInMonth);

    let test = [];
    for (let i = 0; i <= weeksInMonth; i++) {
      test.push([]);
      for (let j = 0; j < 7; j++) {
        test[i].push({
          day: monthStart.add(i * 7 + j, "day").format("MM/DD/YYYY"),
        });
        // console.log(test);
      }
    }

    setMonth(test);
  };

  const moveDate = (dir) => {
    switch (dir) {
      case "prev":
        setUpMonth(firstOfMonth.add(-1, "month"));
        break;
      case "next":
        setUpMonth(firstOfMonth.add(1, "month"));
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if ((month[0] && month[0][0].hd) || month.length === 0) return;
    setUpHebrewCalendar();
    // eslint-disable-next-line
  }, [month]);

  useEffect(() => {
    setUpMonth(props.value);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.calendar}>
        <div className={`df aic m-b-30 p-t-10 ffaffr fs30 ${styles.hdr}`}>
          <p
            className={`df acc ${styles.prev} ${styles.btn}`}
            onClick={() => moveDate("prev")}
          ></p>
          {month[1] ? dayjs(month[1][1]?.day).format("MMMM YYYY") : undefined}
          <p
            className={`df acc ${styles.next} ${styles.btn}`}
            onClick={() => moveDate("next")}
          ></p>
        </div>
        <div>
          {month.map((week, weekIndex) => (
            <div className={`df ${styles.week}`} key={weekIndex}>
              {week.map((day, dayIndex) => (
                <div
                  onClick={() => {
                    props.onChange(day.day);
                  }}
                  key={dayIndex}
                  className={`df ffc ${styles.day} ${
                    dayjs(new Date(day.day)).isSame(new Date(), "day")
                      ? styles.today
                      : ""
                  } ${
                    dayjs(new Date(day.day)).isBefore(dayjs(firstOfMonth)) ||
                    dayjs(new Date(day.day)).isAfter(dayjs(lastOfMonth))
                      ? styles.outside
                      : ""
                  }`}
                >
                  <p className={`ffaffr fs20`}>
                    {dayjs(new Date(day.day)).format("D")}
                  </p>
                  <div className={`mta fs9 ffrr ${styles.list}`}>
                    {/* ar.splice(ar.length - 1,1) */}
                    <div className={`ffrmi ${styles.eventList}`}>
                      {day?.hd?.events
                        .filter((a, i) =>
                          dayjs(new Date(day.day)).day() === 6
                            ? true
                            : !a.includes("Parashat")
                        )
                        .map((event, eventIndex) => (
                          <React.Fragment key={eventIndex}>
                            <p className={styles.bullet}>&bull; </p>
                            <p className={` m-t-2 `}> {event}</p>
                          </React.Fragment>
                        ))}
                    </div>

                    <p className={`df aic ffrr fs12 m-t-4 ${styles.hebDate}`}>
                      {day?.hd?.hd} {day?.hd?.hm}{" "}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Calender;
