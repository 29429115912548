import axios from "../../../axios";
import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import { camelCaseToWords } from "../../../helper";

import styles from "./Slot.module.scss";
import AlreadyBooked from "../already-booked/AlreadyBooked";
import CheckMarkSVG from "../../../assets/img/js-svgs/checkmark.svg";

// const Glider = <></>;

const Slot = (props) => {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date");

  const [slots, setSlots] = useState(undefined);
  const [selectedSlot, setSelectedSlot] = useState();

  const getSlotsForDate = async () => {
    let { data } = await axios.get(`event/?date=${date}`);
    setSlots(data);
  };

  useEffect(() => {
    getSlotsForDate();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.slotPage}>
      {slots?.length ? (
        <>
          <div className={`df aic ffrl fs24 ${styles.stepTitleWrpr}`}>
            <div className={`df acc ffrb ${styles.numberWrpr}`}>2.</div>{" "}
            <p>Now choose your time:</p>
          </div>
          <div className={`${styles.listWrpr}`}>
            <Glider
              slidesToShow={"auto"}
              itemWidth="220"
              // exactWidth
              scrollLock
              draggable
              hasDots
            >
              {/* <Glider
            slidesToShow={"auto"}
            scrollLock
            hasDots
            draggable
            itemWidth="220"
          > */}
              {slots.map((slot, idx) => (
                <div
                  className={`df ffc ${styles.slot}`}
                  key={idx}
                  onClick={() => setSelectedSlot(slot._id)}
                >
                  {selectedSlot === slot._id ? (
                    <div className={styles.checked}>
                      <CheckMarkSVG fill="#FFFEFB" />
                    </div>
                  ) : undefined}
                  <p className={`tac ffaffr fs22 ${styles.title}`}>
                    {slot.title}
                  </p>
                  <p
                    className={`df jcc tac ffaffr ttuc fs12 m-t-6 p-l-12 p-r-12 ${styles.timeSlot}`}
                  >
                    {camelCaseToWords(slot.timeSlot)}
                  </p>
                  <p className={`ffrr fs10 tac m-t-6`}>
                    ({slot.startTime} - {slot.endTime})
                  </p>
                  <p
                    className={`tac ffrl fs16 m-t-15 p-l-12 p-r-12 ${styles.description}`}
                  >
                    {slot.description}
                  </p>
                  <div className={`df jcc mta`}>
                    <p className={`ffaffr fs18 p-t-15`}>
                      ${slot.price.toFixed(2)}
                    </p>
                  </div>
                </div>
              ))}
            </Glider>
          </div>
          <Link
            to={`../pay/?date=${date}&slot=${selectedSlot}`}
            className={`ffrl fs22 mra ${styles.button} ${
              !selectedSlot ? styles.disabled : ""
            }`}
          >
            Next
            <span className={`m-l-30`}>
              <CheckMarkSVG fill="#FFFEFB" />
            </span>
          </Link>
          <Link
            to="../"
            className={`ffrl fs22 mra m-l-8 ${styles.secondaryButton}`}
          >
            Previous
          </Link>
        </>
      ) : (
        <AlreadyBooked />
      )}
    </div>
  );
};

export default Slot;
