import { Outlet } from "react-router-dom";
import styles from "./Admin.module.scss";
import AdminHeader from "./header/Header";

import "react-datepicker/dist/react-datepicker.css";

const Admin = (props) => {
  return (
    <div className={styles.adminPage}>
      <AdminHeader />
      <Outlet />
    </div>
  );
};

export default Admin;
