import { Outlet } from "react-router";
import Header from "../header/Header";

const Home = (props) => {
  return (
    <div className={``}>
      <Header />
      <Outlet />
    </div>
  );
};

export default Home;
