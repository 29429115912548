const CapacityIcon = (props) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0.345001L13.66 5.995C15.22 7.555 16 9.635 16 11.635C16 13.635 15.22 15.745 13.66 17.305C12.1 18.865 10.05 19.655 8 19.655C5.95 19.655 3.9 18.865 2.34 17.305C0.78 15.745 0 13.635 0 11.635C0 9.635 0.78 7.555 2.34 5.995L8 0.345001ZM3.76 7.595C2.62 8.725 2.01 9.995 2 11.995H14C13.99 9.995 13.38 8.765 12.24 7.645L8 3.265L3.76 7.595Z"
      fill={props.fill}
    />
  </svg>
);

export default CapacityIcon;
