import axios from "../../../axios";
import { useEffect, useState } from "react";
import AddEvent from "../add-event/AddEvent";

import styles from "./Event.module.scss";

const Events = (props) => {
  const [events, setEvents] = useState([]);
  const [showAddEvent, setShowAddEvent] = useState(false);

  const toggleAddEvent = () => setShowAddEvent(!showAddEvent);

  const getEvents = async () => {
    console.log("getEvents");
    let { data } = await axios.get("/event");
    setEvents(data);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <div className={styles.eventList}>
        {events.map((event) => (
          <div className={styles.event}>{event.title}</div>
        ))}
      </div>
      <button onClick={toggleAddEvent}>Add event</button>
      {showAddEvent ? <AddEvent close={toggleAddEvent} /> : undefined}
    </>
  );
};

export default Events;
