import axios from "../../../axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import styles from "./AdminCalendar.module.scss";

const AdminCalendar = (props) => {
  const [month, setMonth] = useState([]);

  const getCalendar = async (date = dayjs(new Date()).format("MM/DD/YYYY")) => {
    let { data } = await axios.get(`admin-calendar/?date=${date}`);
    setMonth(data);
  };

  useEffect(() => {
    getCalendar();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`ffrm ${styles.adminCal}`}>
      {month.map((week) => (
        <div className={`${styles.week}`}>
          {week.map((day, i) => (
            <div className={`df ffc ${styles.day}`}>
              <p className={`ffaffr fs20`}>
                {dayjs(new Date(day.day)).format("D")}
              </p>

              <div className={`${styles.reservations}`}>
                {day.reservations?.map((reservation) => (
                  <div className={`df aic ${styles.reservation}`}>
                    <p className={`ffrr fs12`}>{reservation.event.title}</p>
                  </div>
                ))}
              </div>

              <div className={`mta fs9 ffrr ${styles.list}`}>
                {/* ar.splice(ar.length - 1,1) */}
                <div className={`ffrmi ${styles.eventList}`}>
                  {day?.hd?.events
                    .filter((a, i) =>
                      dayjs(new Date(day.day)).day() === 6
                        ? true
                        : !a.includes("Parashat")
                    )
                    .map((event, eventIndex) => (
                      <React.Fragment key={eventIndex}>
                        <p className={styles.bullet}>&bull; </p>
                        <p className={` m-t-2 `}> {event}</p>
                      </React.Fragment>
                    ))}
                </div>

                <p className={`df aic ffrr fs12 m-t-4 ${styles.hebDate}`}>
                  {day?.hd?.hd} {day?.hd?.hm}{" "}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AdminCalendar;
