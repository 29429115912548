import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";

import CalendarSVG from "../../../assets/img/js-svgs/calendar.svg";

import styles from "./AlreadyBooked.module.scss";

const AlreadyBooked = (props) => {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date");

  return (
    <div className={`df ffc acc ${styles.alreadyBooked}`}>
      <span className={`m-b-30`}>
        <CalendarSVG />
      </span>
      <p className={`ffrl fs24 tac`}>
        We're sorry, {props.error ? "we hit a snag," : ""} this date:{" "}
        {dayjs(new Date(date)).format("dddd, MMM DD YYYY")} is{" "}
        {props.error ? "not available at the moment" : "already booked"}.
      </p>
      <Link className={`ffrl fs22 m-t-25 ${styles.button}`} to="../">
        Reserve another date
      </Link>
      {/* <p className={`mta ${styles.contactWrpr}`}>Please contact us...</p> */}
    </div>
  );
};
export default AlreadyBooked;
