import { useState } from "react";
import DatePicker from "react-datepicker";
import update from "immutability-helper";
import dayjs from "dayjs";
import axios from "../../../axios";
import CloseSVG from "../../../assets/img/js-svgs/close.svg";
import styles from "./AddEvent.module.scss";

const AddEvent = (props) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [timeSlot, setTimeSlot] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setSEndDate] = useState();
  const [price, setPrice] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [daysOfWeek, setDaysOfWeek] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const updateDaysOfWeek = (day) => {
    const updatedWeek = update(daysOfWeek, {
      [day]: { $set: !daysOfWeek[day] },
    });

    setDaysOfWeek(updatedWeek);
  };

  const save = async () => {
    console.log({
      title,
      description,
      timeSlot,
      startDate: new Date(
        Number(dayjs(startDate).startOf("day"))
      ).toLocaleString(),
      endDate: new Date(Number(dayjs(endDate).startOf("day"))).toLocaleString(),
      daysOfWeek,
    });

    axios.post("event", {
      title,
      description,
      timeSlot,
      startTime,
      endTime,
      price,
      startDate: new Date(
        Number(dayjs(startDate).startOf("day"))
      ).toLocaleString(),
      endDate: new Date(Number(dayjs(endDate).startOf("day"))).toLocaleString(),
      daysOfWeek,
    });
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.addEvent}>
        <div className={`df aic p-b-15 m-b-20 m-t-8 ${styles.hdr}`}>
          <p className={`ffrr fs18 ttuc`}>Add Event</p>

          <div
            className={`mla m-r-6 cursor-pointer p-l-6 p-r-6 p-b-6 p-t-6`}
            onClick={props.close}
          >
            <CloseSVG fill={"#111"} height="16" width="16" />
          </div>
        </div>
        <div className={`m-b-20 ${styles.inputWrpr}`}>
          <p className={`ffrl fs12 m-b-4`}>Title</p>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className={`m-b-20 ${styles.inputWrpr}`}>
          <p className={`ffrl fs12 m-b-4`}>Description</p>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className={styles.timesWrpr}>
          <div className={`m-b-20 ${styles.inputWrpr}`}>
            <p className={`ffrl fs12 m-b-4`}>Start Time</p>
            <input
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>

          <div className={`m-b-20 ${styles.inputWrpr}`}>
            <p className={`ffrl fs12 m-b-4`}>End Time</p>
            <input
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
        </div>

        <div className={`m-b-20 ${styles.inputWrpr}`}>
          <p className={`ffrl fs12 m-b-4`}>Price</p>
          <input value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>

        <div className={`m-b-20 ${styles.inputWrpr}`}>
          <p className={`ffrl fs12 m-b-10`}>Time slot</p>
          <div className={`df ${styles.slotWrpr}`}>
            <div className={`df ffc acc ${styles.radioWrpr}`}>
              <label
                htmlFor="morning"
                className={`ffrl fs14 ${styles.slotLabel}`}
              >
                Morning
              </label>

              <label
                htmlFor="morning"
                className={`${styles.radio} ${
                  timeSlot === "morning" ? styles.selected : ""
                }`}
              ></label>
              <input
                id="morning"
                className={`dn`}
                onChange={(e) => setTimeSlot(e.target.value)}
                value="morning"
                type="radio"
                name="slot"
              />
            </div>

            <div className={`df ffc acc ${styles.radioWrpr}`}>
              <label htmlFor="noon" className={`ffrl fs14 ${styles.slotLabel}`}>
                Noon
              </label>

              <label
                htmlFor="noon"
                className={`${styles.radio} ${
                  timeSlot === "noon" ? styles.selected : ""
                }`}
              ></label>
              <input
                id="noon"
                className={`dn`}
                onChange={(e) => setTimeSlot(e.target.value)}
                value="noon"
                type="radio"
                name="slot"
              />
            </div>

            <div className={`df ffc acc ${styles.radioWrpr}`}>
              <label
                htmlFor="evening"
                className={`ffrl fs14 ${styles.slotLabel}`}
              >
                Evening
              </label>

              <label
                htmlFor="evening"
                className={`${styles.radio} ${
                  timeSlot === "evening" ? styles.selected : ""
                }`}
              ></label>
              <input
                id="evening"
                className={`dn`}
                onChange={(e) => setTimeSlot(e.target.value)}
                value="evening"
                type="radio"
                name="slot"
              />
            </div>

            <div className={`df ffc acc ${styles.radioWrpr}`}>
              <label
                htmlFor="allday"
                className={`ffrl fs14 ${styles.slotLabel}`}
              >
                All Day
              </label>

              <label
                htmlFor="allday"
                className={`${styles.radio} ${
                  timeSlot === "allday" ? styles.selected : ""
                }`}
              ></label>
              <input
                id="allday"
                className={`dn`}
                onChange={(e) => setTimeSlot(e.target.value)}
                value="allday"
                type="radio"
                name="slot"
              />
            </div>

            <div className={`df ffc acc ${styles.radioWrpr}`}>
              <label
                htmlFor="nightAndDay"
                className={`ffrl fs14 ${styles.slotLabel}`}
              >
                Night And Day
              </label>

              <label
                htmlFor="nightAndDay"
                className={`${styles.radio} ${
                  timeSlot === "nightAndDay" ? styles.selected : ""
                }`}
              ></label>
              <input
                id="nightAndDay"
                className={`dn`}
                onChange={(e) => setTimeSlot(e.target.value)}
                value="nightAndDay"
                type="radio"
                name="slot"
              />
            </div>
          </div>
        </div>
        <div className={`m-b-20 ${styles.dateRange}`}>
          <div className={styles.inputWrpr}>
            <p className={`ffrl fs12 m-b-4`}>From date</p>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className={styles.inputWrpr}>
            <p className={`ffrl fs12 m-b-4`}>End date</p>
            <DatePicker
              selected={endDate}
              onChange={(date) => setSEndDate(date)}
            />
          </div>
        </div>

        <div className={`m-b-20 ${styles.inputWrpr}`}>
          <p className={`ffrl fs12 m-b-4`}>Days of week selection</p>
          <div className={`df aic ${styles.daysWrpr}`}>
            {Object.keys(daysOfWeek).map((day) => {
              return (
                <div
                  className={`ffrl fs14 df acc ttcaps ${styles.day} ${
                    daysOfWeek[day] ? styles.selected : ""
                  }`}
                  onClick={() => updateDaysOfWeek(day)}
                >
                  {day}
                </div>
              );
            })}
          </div>
        </div>
        <div className={`df ${styles.ftr}`}>
          <div className={`mla`}>
            <button className={`fs16 ffrm ${styles.cancel}`}>Cancel</button>
            <button className={`fs16 ffrm ${styles.save}`} onClick={save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEvent;
