import { Link } from "react-router-dom";
import styles from "./Header.module.scss";

const AdminHeader = (props) => {
  return (
    <div className={`ffrm df aic p-l-30 ${styles.adminHdr}`}>
      Admin header <Link to="events">Events</Link>
      <Link to="calendar">Calendar</Link>
    </div>
  );
};

export default AdminHeader;
