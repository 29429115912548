// import { useState } from "react";
// import dayjs from "dayjs";
// import Calender from "../calender/Calender";
// import CheckMarkSVG from "../../assets/img/js-svgs/checkmark.svg";
import styles from "./Book.module.scss";
import { Outlet } from "react-router";

const Book = (props) => {
  return (
    <div className={`df acc ${styles.bookPage}`}>
      <div className={`df acc ${styles.contentWrpr}`}>
        <div className={`df ffc jcc ${styles.innerContent}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Book;
