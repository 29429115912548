import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import CheckMarkSVG from "../../../assets/img/js-svgs/checkmark.svg";
import Calender from "../../calender/Calender";
import styles from "./Date.module.scss";

const BookDate = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState();

  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const dateChange = (date) => {
    console.log(new Date(date));
    setDate(new Date(date));
    setShowCalendar(false);
  };
  return (
    <div className={styles.datePage}>
      <p className={`ffaffr fs30 ${styles.title}`}>Let's book your event</p>
      <div className={`df aic ffrl fs24 ${styles.stepTitleWrpr}`}>
        <div className={`df acc ffrb ${styles.numberWrpr}`}>1.</div>
        <p>Start with reserving your date:</p>
      </div>
      <div
        className={`df aic ffrl fs24 mra ${styles.dateWrpr}`}
        onClick={toggleCalendar}
      >
        <div className={`ffrl fs24 ${styles.calSec}`}>
          {date ? dayjs(date).format("MM") : "MM"}
        </div>
        <p className={styles.divider}>/</p>
        <div className={`ffrl fs24 ${styles.calSec}`}>
          {date ? dayjs(date).format("DD") : "DD"}
        </div>
        <p className={styles.divider}>/</p>
        <div className={`ffrl fs24 ${styles.calSec}`}>
          {date ? dayjs(date).format("YYYY") : "YYYY"}
        </div>
      </div>
      <Link
        to={`slot/?date=${dayjs(date).format("MM-DD-YYYY")}`}
        className={`ffrl fs22 mra ${styles.button} ${
          !date ? styles.disabled : ""
        }`}
      >
        OK
        <span className={`m-l-30`}>
          <CheckMarkSVG fill="#FFFEFB" />
        </span>
      </Link>
      {showCalendar ? (
        <Calender onChange={dateChange} value={date} />
      ) : undefined}
    </div>
  );
};

export default BookDate;
