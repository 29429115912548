import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import styles from "./Landing.module.scss";
import landingBG from "../../assets/img/landing-bg.png";
import CapacityIcon from "../../assets/img/js-svgs/capacity.svg";
import LocationIcon from "../../assets/img/js-svgs/location.svg";

import descImage from "../../assets/img/desc-image.png";
import PhoneIcon from "../../assets/img/js-svgs/phone.svg";

import d1 from "../../assets/img/d1.png";
import d2 from "../../assets/img/d2.png";
import d3 from "../../assets/img/d3.png";
import d4 from "../../assets/img/d4.png";
import d5 from "../../assets/img/d5.png";
import d6 from "../../assets/img/d6.png";
import d7 from "../../assets/img/d7.png";
import d8 from "../../assets/img/d8.png";
import d9 from "../../assets/img/d9.png";
import d10 from "../../assets/img/d10.png";
import d11 from "../../assets/img/d1.png";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useState } from "react";

const Landing = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className={styles.landing}>
      <div className={`df ffc aic content-container`}>
        <div
          className={`df ffc jcc ${styles.landingSec}`}
          style={{ backgroundImage: `url(${landingBG})` }}
        >
          {/* <img alt="" src={landingBG} /> */}
          <h2 className={`ffaffr fs58 ${styles.landingTitle}`}>
            Celebrate your simchah with elegance
          </h2>
          <Link to="book" className={`ffrl fs22 ${styles.bookBtn}`}>
            Book Your Event
          </Link>
        </div>

        <div className={`dg ${styles.detailsSec}`}>
          <p
            className={`${styles.hideMobile} `}
            style={{ gridRow: "1 / 1" }}
          ></p>
          <p
            className={`${styles.hideMobile} `}
            style={{ gridRow: "2 / 2" }}
          ></p>

          <div
            className={`df aic ${styles.withContent}`}
            style={{ gridRow: "3 / 1" }}
          >
            <div className={`df ffc acc ${styles.sec}`}>
              <p className={`df aic ffaffr fs15 ${styles.lbl}`}>
                <span className={styles.iconWrpr}>
                  <CapacityIcon fill={"#AD8140"} />
                </span>
                Capacity
              </p>
              <p className={`tac ffrl fs18 m-t-10 ${styles.val}`}>
                Up to 120 guests
              </p>
            </div>
            <div className={`df ffc acc ${styles.sec}`}>
              <p className={`df aic ffaffr fs15 ${styles.lbl}`}>
                <span className={styles.iconWrpr}>
                  <LocationIcon fill={"#AD8140"} />
                </span>
                Location
              </p>
              <p className={`tac ffrl fs18 m-t-10 ${styles.val}`}>
                33 Union Rd, Spring Valley, NY
              </p>
            </div>
            <div className={`df ffc acc ${styles.sec}`}>
              <p className={`df aic ffaffr fs15 ${styles.lbl}`}>
                <span className={styles.iconWrpr}>
                  <PhoneIcon fill={"#AD8140"} />
                </span>
                Phone
              </p>
              <p className={`tac ffrl fs18 m-t-10 ${styles.val}`}>
                845.263.9898
              </p>
            </div>
          </div>

          <p
            className={`${styles.hideMobile} `}
            style={{ gridRow: "2 / 1" }}
          ></p>
          <p
            className={`${styles.hideMobile} `}
            style={{ gridRow: "2 / 2" }}
          ></p>
        </div>
      </div>

      <div className={`df aic m-t-50 ${styles.descWrpr}`}>
        <div
          className={`ffrl fs18 df aic content-container ${styles.contentContainer}`}
        >
          <div className={`${styles.hideMobile} ${styles.textWrpr}`}>
            <p>
              <span className={`ffaffr`}>Schlesinger Ballroom </span> takes
              pride in providing the ideal setting for your celebration. Our
              newly designed and renovated ballrooms can accommodate events for
              30-120 guests. Ceremony, anniversary parties, bridal &amp; baby
              showers, birthdays, sweet sixteen's, retirement parties, holiday
              parties, proms, end term as well as corporate events are all
              welcomed.
            </p>
            <p className={`m-t-30`}>
              A hidden gem within Monsey with renowned cuisine and superb
              service, When booking your special event at the Schlesinger
              Ballroom you will receive the personal attention of a member of
              the Golden Family. They will oversee every detail to make your
              event unforgettable. The Schlesinger Ballroom would be honored to
              host your special event.
            </p>
          </div>
          <div className={styles.imgWrpr}>
            <img alt="" src={descImage}></img>
          </div>
          <p className={`m-b-30 ${styles.mobileOnly}`}>
            <span className={`ffaffr`}>Schlesinger Ballroom </span> takes pride
            in providing the ideal setting for your celebration. Our newly
            designed and renovated ballrooms can accommodate events for 30-250
            guests. Wedding receptions, ceremony, anniversary parties, bridal
            &amp; baby showers, birthdays, sweet sixteen's, retirement parties,
            holiday parties, proms, end term as well as corporate events are all
            welcomed.
          </p>
          <p className={`${styles.mobileOnly}`}>
            A hidden gem within Brooklyn with renowned cuisine and superb
            service, you will find the Bay Ridge Manor surprisingly affordable.
            When booking your special event at the Bay Ridge Manor you will
            receive the personal attention of a member of the Golden Family.
            They will oversee every detail to make your event unforgettable. The
            Bay Ridge Manor would be honored to host your special event.
          </p>
        </div>
      </div>

      <div className={styles.galleryWrpr}>
        <div className={`content-container`}>
          <h4 className={`ffaffr fs30`}>View Our Gallery</h4>

          <div className={`m--40`}>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                marginBottom: "20px",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d1} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d2} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d3} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d4} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d5} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d6} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d7} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d8} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d9} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d10} />
              </SwiperSlide>
              <SwiperSlide className={styles.mainSlide}>
                <img alt="" src={d11} />
              </SwiperSlide>
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={29.5}
              slidesPerView={"auto"}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d1} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d2} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d3} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d4} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d5} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d6} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d7} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d8} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d9} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d10} />
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <img alt="" src={d11} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
