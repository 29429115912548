import { useEffect, useState } from "react";
import styles from "./Billing.module.scss";
import Cleave from "cleave.js/react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlreadyBooked from "../already-booked/AlreadyBooked";
import axios from "../../../axios";
import "cleave.js/dist/addons/cleave-phone.us.js";

const BillingPage = (props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("slot");
  const eventDate = searchParams.get("date");

  const [submitting, setSubmitting] = useState(false);
  const [cvvToken, setCvvToken] = useState("");
  const [ccToken, setCcToken] = useState("");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [nameOnCard, setNameOnCard] = useState();
  const [expireDate, setExpireDate] = useState();
  const [bookingError, setBookingError] = useState(undefined);

  const getCCTokens = () => {
    window.getTokens(() => {
      let cardToken = document.querySelector(
        "[data-ifields-id='card-number-token']"
      ).value;
      let cvvToken = document.querySelector(
        "[data-ifields-id='cvv-token']"
      ).value;

      setCcToken(cardToken);
      setCvvToken(cvvToken);
    });
  };

  // 1334 + 236 + 161 + 61 + 221

  const setupCardknox = async () => {
    window.setAccount(
      "ifields_ShmilicomDev_Test2_811847608264488db4",
      "Shmili.com99",
      "0.1.2"
    );

    let defaultStyle = {
      height: "initial",
      color: "black",
      border: "1px solid rgba(15, 17, 60, 0.25)",
      "border-width": "0px 0px 1px 0px",
      "border-radius": "0px",
      transition: "all 0.2s",
      padding: "6px 0px 8px 0px",
      width: "100%",
      outline: "none",
      "box-sizing": "border-box",
      "font-size": "16px",
      "font-family": "roboto light",
    };

    let invalidStyle = {
      height: "initial",
      border: "1px solid #dd0624",
      color: "#dd0624",
      "border-width": "0px 0px 1px 0px",
      "border-radius": "0px",
      transition: "all 0.2s",
      padding: "6px 0px 8px 0px",
      width: "100%",
      outline: "none",
      "box-sizing": "border-box",
      "font-size": "16px",
    };

    window.addIfieldCallback("blur", (data) => {
      if (
        data.triggeredByIfield === "card-number" &&
        !data.cardNumberIsEmpty &&
        !data.cardNumberIsValid
      ) {
        window.setIfieldStyle("card-number", invalidStyle);
      }

      if (
        data.triggeredByIfield === "cvv" &&
        !data.cvvIsEmpty &&
        !data.cvvIsValid
      ) {
        window.setIfieldStyle("cvv", invalidStyle);
      }
    });

    window.addIfieldCallback("input", (data) => {
      if (!data.cardNumberIsValid || !data.cvvIsValid) {
        setCvvToken(undefined);
        setCcToken(undefined);
      }
      if (data.cardNumberIsValid && data.cvvIsValid) getCCTokens();

      if (data.ifieldValueChanged) {
        if (
          (data.lastIfieldChanged === "card-number" &&
            data.cardNumberIsValid) ||
          data.cardNumberIsEmpty
        ) {
          window.setIfieldStyle("card-number", defaultStyle);
        }

        if (
          (data.lastIfieldChanged === "cvv" && data.cvvIsValid) ||
          data.cvvIsEmpty
        ) {
          window.setIfieldStyle("cvv", defaultStyle);
        }
      }
    });

    await window.setIfieldStyle("card-number", defaultStyle);
    await window.setIfieldStyle("cvv", defaultStyle);
    await window.enableAutoFormatting("-");
  };

  useEffect(() => {
    setupCardknox();
    // eslint-disable-next-line
  }, []);

  const processPayment = async () => {
    setSubmitting(true);
    let res = await axios
      .post("reserve", {
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        nameOnCard,
        ccToken,
        expireDate,
        cvvToken,
        eventId,
        eventDate,
      })
      .catch((e) => e);

    if (res?.response?.status === 500) {
      setBookingError("Error saving booking please...");
    } else {
      navigate(`../confirmed/?booking=${res.data._id}`);
    }

    setSubmitting(false);
  };
  return (
    <div className={`df ffc acc ${styles.billingPage}`}>
      {!bookingError ? (
        <>
          <p className={`ffrl fs24`}>Please share your billing info:</p>

          <div className={`df m-t-30 ${styles.innerWrpr}`}>
            <div className={styles.sec}>
              <div className={`df ${styles.line}`}>
                <div
                  className={`df ffc m-r-5 ${styles.half} ${styles.inputWrpr}`}
                >
                  <label className={`ffrl fs14`}>First Name</label>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={`fs16 ffrl`}
                  />
                </div>
                <div
                  className={`df ffc m-l-5 ${styles.half} ${styles.inputWrpr}`}
                >
                  <label className={`ffrl fs14`}>Last Name</label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={`fs16 ffrl`}
                  />
                </div>
              </div>

              <div className={`df m-t-30 ${styles.line}`}>
                <div className={`df ffc ${styles.inputWrpr}`}>
                  <label className={`ffrl fs14`}>Email Address</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`fs16 ffrl`}
                  />
                </div>
              </div>

              <div className={`df m-t-30 ${styles.line}`}>
                <div className={`df ffc ${styles.inputWrpr}`}>
                  <label className={`ffrl fs14`}>Phone Number</label>
                  {/* <input className={`fs16 ffrl`} /> */}

                  <Cleave
                    style={{ fontSize: "16px", fontFamily: "roboto light" }}
                    // placeholder="Phone number"
                    options={{
                      blocks: [0, 3, 3, 4],
                      // delimiters: ["(", ") ", "-"],
                      numericOnly: true,
                      // phone: true,
                      delimiters: ["(", ") ", "-"],
                      // phoneRegionCode: "us",
                    }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className={`df m-t-30 ${styles.line}`}>
                <div className={`df ffc ${styles.inputWrpr}`}>
                  <label className={`ffrl fs14`}>Address</label>
                  <input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className={`fs16 ffrl`}
                  />
                </div>
              </div>
            </div>
            <div className={styles.sec}>
              <div className={`df ${styles.line}`}>
                <div className={`df ffc ${styles.inputWrpr}`}>
                  <label className={`ffrl fs14`}>Name on card</label>
                  <input
                    value={nameOnCard}
                    onChange={(e) => setNameOnCard(e.target.value)}
                    className={`fs16 ffrl`}
                  />
                </div>
              </div>

              <div className={`df m-t-30 ${styles.line}`}>
                <div className={`df ffc ${styles.inputWrpr}`}>
                  <label className={`ffrl fs14`}>Card number</label>
                  <iframe
                    title="card"
                    width="100%"
                    height="34px"
                    data-ifields-id="card-number"
                    data-ifields-placeholder=""
                    src="https://cdn.cardknox.com/ifields/2.6.2006.0102/ifield.htm"
                  ></iframe>
                  <input
                    data-ifields-id="card-number-token"
                    name="xCardNum"
                    type="hidden"
                  ></input>
                </div>
              </div>

              <div className={`df m-t-30 ${styles.line}`}>
                <div
                  className={`df ffc m-r-5 ${styles.half} ${styles.inputWrpr}`}
                >
                  <label className={`ffrl fs14`}>Expiry date</label>
                  <Cleave
                    style={{ fontSize: "16px", fontFamily: "roboto light" }}
                    options={{ date: true, datePattern: ["m", "y"] }}
                    value={expireDate}
                    onChange={(e) => setExpireDate(e.target.value)}
                  />
                </div>
                <div
                  className={`df ffc m-l-5 ${styles.half} ${styles.inputWrpr}`}
                >
                  <label className={`ffrl fs14`}>CVV</label>
                  <iframe
                    title="cvv"
                    width="100%"
                    height="34px"
                    data-ifields-id="cvv"
                    data-ifields-placeholder=""
                    src="https://cdn.cardknox.com/ifields/2.6.2006.0102/ifield.htm"
                  ></iframe>
                  <input
                    data-ifields-id="cvv-token"
                    name="xCVV"
                    type="hidden"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <button
            disabled={
              !cvvToken ||
              !ccToken ||
              !firstName ||
              !lastName ||
              !email ||
              !phoneNumber ||
              !address ||
              !nameOnCard ||
              !expireDate ||
              submitting
            }
            onClick={processPayment}
            className={`ffrl fs22 m-t-25 p-l-50 p-r-50 m-t-30`}
          >
            Process Payment
          </button>
        </>
      ) : (
        <AlreadyBooked error={true} />
      )}
    </div>
  );
};

export default BillingPage;
