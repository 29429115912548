import { Link } from "react-router-dom";
import CalendarSVG from "../../../assets/img/js-svgs/clap.svg";

import styles from "./ReservationConfirmed.module.scss";

const ReservationConfirmed = (props) => {
  return (
    <div className={`df ffc acc ${styles.reservationConfirmed}`}>
      <CalendarSVG />
      <p className={`ffrl fs24 tac m-t-20`}>
        Congratulations! Your reservation is completed.
      </p>

      <Link
        className={`ffrl fs22 m-t-25 p-l-50 p-r-50 m-t-30 ${styles.button}`}
        to="/hello-reservation"
      >
        View Reservation Details
      </Link>

      <p
        className={`tac ffrl fs14 m-t-25 p-l-50 p-r-50 m-t-60 ${styles.checkEmail}`}
      >
        Also check your email for reservation details.
      </p>
    </div>
  );
};

export default ReservationConfirmed;
