const CloseSVG = (props) => (
  <svg
    width={props.width || "10"}
    height={props.height || "10"}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85938 8.14648C9.08789 8.40039 9.08789 8.78125 8.85938 9.00977C8.60547 9.26367 8.22461 9.26367 7.99609 9.00977L5 5.98828L1.97852 9.00977C1.72461 9.26367 1.34375 9.26367 1.11523 9.00977C0.861328 8.78125 0.861328 8.40039 1.11523 8.14648L4.13672 5.125L1.11523 2.10352C0.861328 1.84961 0.861328 1.46875 1.11523 1.24023C1.34375 0.986328 1.72461 0.986328 1.95312 1.24023L5 4.28711L8.02148 1.26562C8.25 1.01172 8.63086 1.01172 8.85938 1.26562C9.11328 1.49414 9.11328 1.875 8.85938 2.12891L5.83789 5.125L8.85938 8.14648Z"
      fill={props.fill || "#eeeeee"}
    />
  </svg>
);

export default CloseSVG;
