const ClapSVG = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.8298 20.2931L10.5023 23.5681C12.4162 24.6731 14.8614 24.0179 15.9664 22.104L22.8914 10.1095C23.5814 8.9144 23.1714 7.38445 21.9763 6.69445C21.8291 6.60945 21.6719 6.54177 21.5183 6.48775L21.6583 6.24527C22.3483 5.05015 21.9383 3.5202 20.7432 2.8302C20.4574 2.6652 20.1503 2.55716 19.8392 2.51609C19.7795 1.71951 19.3449 0.972119 18.6002 0.542119C17.457 -0.117881 16.0067 0.234125 15.2787 1.31496C15.1465 1.20398 15.0093 1.10166 14.8621 1.01666C13.667 0.326657 12.137 0.736605 11.447 1.93172L6.67201 10.2023L5.22489 7.88875C4.78769 7.186 3.94461 6.82626 3.13267 6.99258L0.425 7.5424L2.94199 17.7829C3.2015 18.8334 3.89449 19.7531 4.8298 20.2931ZM4.88406 17.2991L2.85915 9.08633L3.52583 8.95162L6.74406 14.0775L13.1791 2.93172C13.3141 2.69789 13.6196 2.60871 13.8621 2.74871C14.1046 2.88871 14.1851 3.18923 14.0451 3.43172L10.4401 9.67576L12.1721 10.6758L16.9171 2.45718C17.0571 2.2147 17.3577 2.13417 17.6002 2.27417C17.8426 2.41417 17.9232 2.7147 17.7832 2.95718L13.0382 11.1758L14.7702 12.1758L19.0602 4.74527C19.2002 4.50278 19.5007 4.42225 19.7432 4.56225C19.9857 4.70225 20.0662 5.00278 19.9262 5.24527L15.6362 12.6758L17.3683 13.6758L20.2933 8.60951C20.4333 8.36703 20.7338 8.2865 20.9763 8.4265C21.2188 8.5665 21.2993 8.86703 21.1593 9.10951L14.2343 21.104C13.6843 22.0566 12.4549 22.386 11.5023 21.836L5.82114 18.556C5.35349 18.286 5.01815 17.8268 4.88406 17.2991Z"
      fill="#AD8140"
    />
  </svg>
);

export default ClapSVG;
