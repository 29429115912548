import { Routes, Route } from "react-router-dom";
import Admin from "../components/admin/Admin";
import AdminCalendar from "../components/admin/calendar/AdminCalendar";
import Events from "../components/admin/events/Event";
import BillingPage from "../components/book/billing/Billing";
import Book from "../components/book/Book";
import BookDate from "../components/book/date/Date";
import ReservationConfirmed from "../components/book/reservation-confirmed/ReservationConfirmed";
import Slot from "../components/book/slot/Slot";
// import Details from "../components/details/Details";
import Home from "../components/home/Home";
import Landing from "../components/landing/Landing";

const Router = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="" element={<Landing />} />
      </Route>
      <Route path="/book" element={<Book />}>
        <Route path="" element={<BookDate />}></Route>
        <Route path="slot" element={<Slot />}></Route>
        <Route path="pay" element={<BillingPage />}></Route>
        <Route path="confirmed" element={<ReservationConfirmed />}></Route>
      </Route>
      <Route path="/admin" element={<Admin />}>
        <Route path="events" element={<Events />}></Route>
        <Route path="calendar" element={<AdminCalendar />}></Route>
      </Route>
    </Routes>
  );
};

export default Router;
