import { NavLink } from "react-router-dom";
import styles from "./Header.module.scss";

import sbLogo from "../../assets/img/sb.svg";

const Header = (props) => {
  return (
    <div className={styles.header}>
      <div className={`df aic content-container`}>
        <div className={styles.logoWrpr}>
          <img src={sbLogo} alt="" />
        </div>
        <div className={`mla df aic ffrl fs18 ${styles.navsWrpr}`}>
          {/* <NavLink to="/">Home</NavLink>
          <NavLink to="/">Gallery</NavLink> */}
          <NavLink to="/book">Book</NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
